import { User } from "../types/user.type";

const authHeader = () => {
    
    const str:any = localStorage.getItem('user'); 
    const user:User  = JSON.parse(str);

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }; //
      } else {
        return {};
      }
}

export default authHeader;