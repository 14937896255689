import React from 'react';
import { Grid, styled } from '@mui/material';

const CenteredGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const MainwinBodyImage = styled('img')({
    width: '50vw',
    height: '65vh',
});

const StyledText = styled('div')({
    fontFamily: 'Acme, sans-serif',
    fontSize: '15px',
    lineHeight: 1.6,
    margin: '20px',
});


const Body: React.FC = () => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <CenteredGrid>
                    <MainwinBodyImage src="https://img.freepik.com/premium-vector/people-tree-sign-color-green-white-background-vector-illustration_3482-5142.jpg" alt="family" />
                </CenteredGrid>
            </Grid>
            <Grid item xs={6}>
                <CenteredGrid>
                    <StyledText>
                        О, генеалогическое дерево - это что-то волшебное! Представь, что ты - детектив, который изучает своё собственное прошлое!
                        <br />
                        <br />
                        У тебя есть бабушка и дедушка, правильно? И у них тоже были родители, дедушка и бабушка, и так далее. Так вот, генеалогическое дерево - это способ показать все этих людей в твоей жизни, начиная от самых давних предков и заканчивая современными членами семьи.
                        <br />
                        <br />
                        Такая схема дает возможность визуализировать свои семейные связи, изучать историю своей семьи, и даже отслеживать генетические черты, которые могут передаваться из поколения в поколение. Ты создаешь карту своего родословного дерева, чтобы лучше понять, откуда пришли твои корни.
                        <br />
                        <br />
                        При построении генеалогического дерева важно учитывать разные факторы, такие как имена, даты рождения, семейные статусы.
                        <br />
                        <br />
                        В общем, генеалогическое дерево - это как волшебная карта, которая позволяет тебе погрузиться в историю своих предков и узнать много нового о своей семье.
                        <br />
                        <br />
                        А ты уже строил своё генеалогическое дерево? Или задумывался об этом?
                    </StyledText>
                </CenteredGrid>
            </Grid>
        </Grid>
    );

}

export default Body;
