import React from 'react';
import './App.css';
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import Login from "./components/login/login.component";
import Register from "./components/register/register.component";
import Home from "./components/home/home.component";
import Profile from "./components/profile/profile.component";
import Graph from './components/graph/Graph/Graph';
import Albums from './components/usercontent/albums/albums/albums.component';
import AlbumWithPhoto from './components/usercontent/albums/album/album.component';
import RenderComponentDialog from './components/layout/RenderComponentDialog';
import Family from './components/usercontent/family/family.component';
import FamilyPassport from './components/usercontent/family/family.component.passport';
import PersonPassport from './components/usercontent/person/person.component.passport';
import Usercontent from './components/usercontent/usercontent.component';
import CreateAlbum from './components/usercontent/albums/create-album.component';
import Photo from './components/usercontent/albums/photo/photo.component';
import SlideShowPhoto from './components/usercontent/albums/album/slideshow.component';


function App() {
  

  return (
    <div className="App">
     
    <BrowserRouter>
      <nav>
      {/* <ButtonAppBar></ButtonAppBar> */}
      </nav>
      <Routes>
        {/* <Route path='/' element={<Home textIn = {true} />} /> */}
        <Route path='/' element={<Home />} />

        {/* <Route path="Login" element={<PageLogin />} /> */}
        <Route path="Login" element={<Login />} />

        {/* <Route path="Register" element={<PageRegister />} /> */}
        <Route path="Register" element={<Register />}/>

        <Route path="Profile" element={<RenderComponentDialog component={<Profile />} />} />

        <Route path="/Graph/:treeId" element={<Graph />} />

        <Route path="/Albums/:userName" element={<Albums />} />

        <Route path="AlbumWithPhoto/" element={<AlbumWithPhoto />} />

        <Route path="Photo/" element={<Photo />} />

        <Route path="CreateAlbum/" element={<RenderComponentDialog component={<CreateAlbum />} />} />

        <Route path="/MyFamily/:userName" element={<Family />} />

        <Route path="/FamilyPassport/:familyId" element={<FamilyPassport />} />

        <Route path="/PersonPassport/" element={<PersonPassport />} />

        <Route path="SlideShowPhoto" element={<SlideShowPhoto />} />

      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
