import React, {useState} from 'react';
import {TextField, Button, Typography, createTheme, ThemeProvider} from '@mui/material';
import {blue} from '@mui/material/colors';
import { getCurrentUser } from '../../services/auth.service';


interface User {
    displayName: unknown;
    login: string;
    username: string;
    password: string;
    email: string;
}

createTheme();
const theme = createTheme({
    typography: {
        fontFamily: 'Acme, sans-serif',
    },
    palette: {
        primary: blue,
        secondary: {
            main: '#212121',
            light: '#F5EBFF',
            contrastText: '#fafafa',
        },
    },
});

const UserSettingsPage: React.FC = () => {
    // Получаем текущего пользователя
    const currentUser = getCurrentUser();
    const [user, setUser] = useState<User>(currentUser); // Установка начального состояния пользователем
    //console.log(user);

    // Обработчики изменения
    const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, displayName: event.target.value });
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, email: event.target.value, username: event.target.value});
    };
    const handleSaveChanges = () => {
        // Как-то надо отправить данные на сервер..( А пока что так
        console.log("Сохранение изменений:", user);
    };


    return (
        <ThemeProvider theme={theme}>
            <Typography variant="h5" gutterBottom>
                Изменить данные пользователя
            </Typography>
            <TextField
                label="Ваш логин:"
                type="text"
                value={user.displayName}
                onChange={handleLoginChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Ваш адрес электронной почты:"
                type="email"
                value={user.email}
                onChange={handleEmailChange}
                fullWidth
                margin="normal"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                color="secondary"
                onClick={handleSaveChanges}
            >
                Сохранить изменения
            </Button>
        </ThemeProvider>
    );
};

export default UserSettingsPage;