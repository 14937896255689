import axios, {AxiosError}  from "axios";

//const API_URL = "https://localhost:7106/auth/";
const API_URL = process.env.REACT_APP_BACK_URL as string + "/auth/";

export const register = (email: string, 
    password: string, 
    passwordConfirm: string, 
    displayName: string) => {
  return axios.post(API_URL + "register", {
    email,
    password,
    passwordConfirm,
    displayName,
  })
  .then((response) => {
    console.log(response.data);
  });
};

export const login = (email: string, password: string) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {

      console.log("response.data.token:");
      console.log(response.data.token);

      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("isLoggedIn", "true");
      }

      console.log("Получили ответ:");
      console.log(response.data);
      return response.data;
    })
    .catch(error => {
      if (!error.response) {
          // network error
          alert("Отсутствует связь с сервером. Проверьте подключение к интернету");
      } else {
        if (axios.isAxiosError(error))  {
          // Access to config, request, and response
          alert("Введены неверные учётные данные");
          console.log("Введены неверные учётные данные");
        } else {
          // Just a stock error
          console.log("Поймали ошибку...");
        }
      }

      throw error;
    })
    // .catch((err: Error | AxiosError) => 
    // {
    //   if (axios.isAxiosError(err))  {
    //     // Access to config, request, and response
    //     alert("Введены неверные данные");
    //     console.log("Введены неверные данные");
    //   } else {
    //     // Just a stock error
    //     console.log("Поймали ошибку...");
    //   }
    //   throw err;
    // })
    ;
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("isLoggedIn");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const getIsLoggedIn = () => {
  const isLoggedInStr = localStorage.getItem("isLoggedIn");
  if (isLoggedInStr) return JSON.parse(isLoggedInStr);

  return null;
};
