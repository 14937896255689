import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';

interface FamilyListProps {
    items: {id: string, name: string, description: string}[];
    openFamilyParent: (familyGuid:string) => void,
};

const FamilyList: React.FC<FamilyListProps> = (props) => {
    
    const openFamily = (index: string) => {
        props.openFamilyParent(index);
    };
    
    return (    
        <List sx={{ alignContent:'center', marginLeft: '0%', width: '100%', bgcolor: 'background.paper'}}>
            {props.items.map((value) => (
            <ListItem
                key={value.id}
                disablePadding
                sx = {{bgcolor:'lightgray', marginTop:'1px'}}
            >
                <ListItemButton onClick={(event) => openFamily(value.id) }>
                <ListItemText id={value.id} primary={`${value.name}: ${value.description}`}/>
                </ListItemButton>
            </ListItem>
            ))}
        </List>
    );
};

export default FamilyList;