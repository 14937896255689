import React from 'react';
import { styled } from '@mui/system';
import { Grid, Paper } from '@mui/material';
import { Telegram, YouTube } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    backgroundColor: 'black',
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: 0,
    height: '100%',
    borderTop: '1px solid black',
    boxShadow: '10px -10px 10px -10px rgba(0, 0, 0, 0.5)',
}));

const WhiteIcon = styled('div')`
  color: white;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Footer: React.FC = () => {
    return (
        <Grid container spacing={0} style={{ height: '100%' }}>
            <Grid item xs={4}>
                <Item>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>

            <Grid item xs={4}>
                <Item>ГЕНЕАЛОГИЧЕСКИЙ ПОРТАЛ </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>
            <Grid item xs={4}>
                <Item> </Item>
            </Grid>
        </Grid>
    );
}

export default Footer;