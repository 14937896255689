export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_FAMILY = "SET_FAMILY"
export const CLEAR_FAMILY = "CLEAR_FAMILY"

export const SET_PERSON = "SET_PERSON"
export const CLEAR_PERSON = "CLEAR_PERSON"

export const SET_PERSONS = "SET_PERSONS"
export const CLEAR_PERSONS = "CLEAR_PERSONS"

export const SET_ALBUMS = "SET_ALBUMS";
export const CLEAR_ALBUMS = "CLEAR_ALBUMS";

export const ADD_ALBUM = "ADD_ALBUM"
export const DELETE_ALBUM = "DELETE_ALBUM"