import testik from '../people.json';
import testikBig from '../people.json';
import type { Node } from 'relatives-tree/lib/types';

export const NODE_WIDTH = 260;
export const NODE_HEIGHT = 280;

export const SOURCES = {
  'people.json': testik,
  'peopleBig.json': testikBig,
} as Readonly<{ [key: string]: readonly Readonly<Node>[] }>;

export const DEFAULT_SOURCE = Object.keys(SOURCES)[0];

export const URL_LABEL = 'URL (Gist, Paste.bin, ...)';
