import {
REGISTER_SUCCESS,
REGISTER_FAIL,
LOGIN_SUCCESS,
LOGIN_FAIL,
LOGOUT,
} from "../actions/types";

import { User } from "../types/user.type";

// глобальный стейт
interface AuthState {
    user: User | null;
    isLoggedIn: boolean;
}

//исходный стейт 
const initialState: AuthState = {
    user: {username: "", email: "", password: "none"},
    isLoggedIn: false,
}


type Action = { type: string, payload?: any };

// редюсер - функция, на основе входного объекта 
// меняющая стейт и возвращающая новый
const authReducer = (state = initialState, action: Action) => {

    //console.log('user', state);

    switch (action.type) {
        case REGISTER_SUCCESS: 
            return { ...state, isLoggedIn: false, };

        case REGISTER_FAIL: 
            return { ...state, isLoggedIn: false, };

        case LOGIN_SUCCESS: 
            //return { ...state, isAuth: action.payload };
            return { ...state, isLoggedIn: true, user: action.payload };
        
        case LOGIN_FAIL: 
            //return { ...state, isAuth: action.payload };
            return { ...state, isLoggedIn: false, user: null };

        case LOGOUT:
            return {...state, isLoggedIn: false, user: null,};
        
        default:
            return state;
    }
};

export default authReducer;