import { SET_FAMILY, CLEAR_FAMILY, SET_PERSON, CLEAR_PERSON, SET_PERSONS, CLEAR_PERSONS } from "../actions/types";
import { PersonModel } from "../models/people/PersonModel";

// глобальный стейт
interface FamilyState {
    currentFamilyGuid: string;
    currentPersonGuid: string;
    currentPersonsFroFamily: PersonModel[]
}

//исходный стейт 
const initialState = {};


type Action = { type: string, payload?: any };

export const Actions = Object.freeze({
    setFamily: (familyGuid?: string) => ({ type: SET_FAMILY, payload: familyGuid }),
    setPerson: (personGuid?: string) => ({ type: SET_PERSON, payload: personGuid }),
    setPersonsForFamily: (persons?: PersonModel[]) => ({ type: SET_PERSONS, payload: persons }),
});

// редюсер - функция, на основе входного объекта 
// меняющая стейт и возвращающая новый
const familyReducer = (state = initialState, action: Action) => {

    console.log('family', state);

    switch (action.type) {
        case SET_FAMILY: 
            return { ...state, currentFamilyGuid: action.payload, };

        case CLEAR_FAMILY: 
            return { ...state, currentFamilyGuid: "", };

        case SET_PERSON: 
            return { ...state, currentPersonGuid: action.payload, };

        case CLEAR_PERSON: 
            return { ...state, currentPersonGuid: "", };

        case SET_PERSONS: 
            return { ...state, currentPersonsFroFamily: action.payload, };

        case CLEAR_PERSONS: 
            return { ...state, currentPersonsFroFamily: [], };
        
        default:
            return state;
    }
};

export default familyReducer;