import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";



// глобальный стейт
interface MsgState {
    message: string;
}

//исходный стейт 
const initialState = {};


type Action = { type: string, payload?: any };

// редюсер - функция, на основе входного объекта 
// меняющая стейт и возвращающая новый
const msgReducer = (state = initialState, action: Action) => {

    //console.log('user', state);

    switch (action.type) {
        case SET_MESSAGE: 
            return { ...state, message: action.payload, };

        case CLEAR_MESSAGE: 
            return { ...state, message: "", };
        
        default:
            return state;
    }
};

export default msgReducer;