import * as React from 'react';
import Header from '../home/Frame/Header/Header';
import Footer from '../home/Frame/Footer/Footer';
import { Box, Container } from '@mui/material';

const AppLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Header sx={{ flexGrow: 0, width: '100%' }} /> {/* Устанавливаем ширину на 100% */}
      <Container sx={{ flexGrow: 1, width: '100%', display: 'flex', paddingLeft: '10%', paddingRight: '10%' }}>
        {children}
      </Container>
      <Footer />
    </Box>
  );
};




export default AppLayout;
