import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SET_ALBUMS, CLEAR_ALBUMS, ADD_ALBUM, DELETE_ALBUM } from "../actions/types";
import { Album } from "../components/usercontent/albums/album.models";

export interface AlbumsState {
    albums: Album[] | null
}

//исходный стейт 
const initialState: AlbumsState = {
    albums: null,
};


type Action = { type: string, payload?: any };


export const albumsSlice = createSlice({
    name: 'albums',
    initialState,
    reducers: {
        setAlbums: (state, action: PayloadAction<Album[]>) => {
            state.albums = action.payload
        },
    }
})

export const {setAlbums} = albumsSlice.actions

export default albumsSlice.reducer