import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { blue } from '@mui/material/colors';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import AppLayout from '../../../layout/AppLayout';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAlbumAsync } from '../../../../services/photo.service';
import { Album, Image } from '../album.models';
import { Box, Button } from '@mui/material';

const theme = createTheme({
    palette: {
      primary: blue,
      secondary: {
        main: '#212121',
        light: '#F5EBFF',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#fafafa',
      },
    },
  });

const divStyle = {
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '800px',
  weight: '800px'
}

const SlideShowPhoto = () => {
    const [queryParams] = useSearchParams();
    const albumId = queryParams.get("albumId") as string;
    let navigate: NavigateFunction = useNavigate();
    const [album, setAlbum] = useState<Album>();

    useEffect(() => {
        console.log("This is an album component");
        loadData();
      },[]);

    const loadData = async () => {
      const id = queryParams.get("albumId") as string;
      getAlbumAsync(id).then(
        (rcvData) => {
          setAlbum(rcvData);
        },
        (error) => {
            console.log(error);
        }
      );
    };

    return (
        <ThemeProvider theme={theme}>
            <AppLayout>
                <Container component="main" maxWidth="xl">
                    <div>
                        {album ? ( // проверяем, определен ли album
                            <Slide>
                                {album.images.map((slideImage, index)=> (
                                    <div key={index}>
                                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                        {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                                    </div>
                                    </div>
                                ))} 
                            </Slide>    
                        ) : (
                            <p>Загрузка...</p>
                        )}    
                    </div>
                    <Box m={1} sx={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'flex-center' }}>
                        <Button variant="outlined" sx={{ mt: 1, mb: 1, }} color="secondary" onClick={() => navigate('/AlbumWithPhoto?id=' + album?.id) } >Вернуться в альбом</Button>
                    </Box>
                </Container>
                
            </AppLayout>
        </ThemeProvider>
    )
}

export default SlideShowPhoto;