import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lime, blue } from '@mui/material/colors';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { login } from "../../services/auth.service";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../../actions/types";

import { User } from "../../types/user.type";
import {Notify} from "../notify/Notify";
import {useState} from 'react';

import AppLayout from '../layout/AppLayout';
// добавляем тип для импортированного компонента
type AppLayoutProps = {
  children: React.ReactNode; // или более конкретный тип, если у вас есть заранее определенный тип children
};



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#212121',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fafafa',
    },
  },
});

export default function Login() {

  let navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();
  let userLgn: User = {
    email: "",
    password: "",
    username: "",
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    let psw: string = data.get('password') as string;
    let lgn: string = data.get('email') as string;

    console.log({ emailRead: lgn, passwordRead: psw });
    login(lgn, psw).then(
      (rcvData) => {
        userLgn.email = rcvData.email;
        //userLgn.password =  rcvData.password;
        userLgn.username = rcvData.username;
        userLgn.token = rcvData.token;
        userLgn.refreshtoken = rcvData.refreshtoken;

        console.log("Login component rcvData.email = " + rcvData.email);

        dispatch({
          type: LOGIN_SUCCESS,
          payload: userLgn,
        });
        navigate("/"); //ПРАВИТЬ ТУТ!
        //window.location.reload();

      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        //setLoading(false);
        //setMessage(resMessage);

      }
    ).catch(error => {
      console.log("Error login...");
    });
  };

  return (
    <AppLayout>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{height: '58vh'}}>
          <CssBaseline/>
          <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                //backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                backgroundImage: 'url(https://img.freepik.com/premium-vector/people-tree-sign-color-green-white-background-vector-illustration_3482-5142.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
              <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Вход
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Введите адрес электронной почты"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Введите пароль"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary"/>}
                    label="Запомнить меня"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    color="secondary"
                >
                  Войти
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      <Notify/>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/Register" variant="body2">
                      {"У вас нет учетной записи?"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>            
          </Grid>
        </Grid>
      </ThemeProvider>
      </AppLayout>
  );
}