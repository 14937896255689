import axios from "axios";
import testik from '../people.json';
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_URL as string + "/graph";

export const getGraph = (familyId:string) => {
    return axios.get(API_URL + "/" + familyId, { headers: authHeader() })
        .then((response) => {
            return response.data.nodeModelList;   
        })
        .catch(error => {
            if (familyId){
                alert("Не найдено ни одного сгенерированного дерево для этого пользователя") //TODO: ПЕРЕДЕЛАТЬ НА ВСПЛЫВАШКУ
            }
            return testik;
        });
};