import { Button, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import AppLayout from "../../layout/AppLayout";
import { blue } from "@mui/material/colors";

const theme = createTheme({
    typography: {
        fontFamily: 'Acme, sans-serif',
    },
    palette: {
        primary: blue,
        secondary: {
            main: '#212121',
            light: '#F5EBFF',
            contrastText: '#fafafa',
        },
    },
});

export default function CreateAlbum() {
    return (
        <ThemeProvider theme={theme}>
            <Typography variant="h5" gutterBottom>
                Изменить данные пользователя
            </Typography>
            <TextField
                label="Название альбома:"
                //value={user.login}
                //onChange={handleLoginChange}
                fullWidth
                margin="normal"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                color="secondary"
            >
                Добавить альбом
            </Button>
        </ThemeProvider>
    );
}