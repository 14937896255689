import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import type { Node, ExtNode } from 'relatives-tree/lib/types';
import ReactFamilyTree from 'react-family-tree';
import { SourceSelect } from '../SourceSelect/SourceSelect';
import { FamilyNode } from '../FamilyNode/FamilyNode';
import { NodeDetails } from '../NodeDetails/NodeDetails';
import { getGraph } from "../../../services/graph.service";
import { NODE_WIDTH, NODE_HEIGHT, SOURCES, DEFAULT_SOURCE } from '../../const';


import css from './Graph.module.css';
import { EextNode, getNodeStyle } from './utils';


import AppLayout from '../../layout/AppLayout';
import Sidebar from '../../sidebar/Sidebar';
import SidebarTree from '../../sidebar/SidebarTree';
import { Button, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default React.memo(
  function Graph() {
    const familyGuid = useSelector((gs: any) => gs.family.currentFamilyGuid);
    const [nodes, setNodeList] = useState<Node[]>([]);
    const [firstNodeId, setFirstNodeId] = useState<string>("");
    const [rootId, setRootId] = useState(firstNodeId);
    const [selectId, setSelectId] = useState<string>();
    const [hoverId, setHoverId] = useState<string>();
    const resetRootHandler = useCallback(() => setRootId(firstNodeId), [firstNodeId]);

      getGraph(familyGuid).then(
        (rcvData) => {
          const n = rcvData as Readonly<Node>[];
          setNodeList(n);
          setFirstNodeId(n[0].id);
          setRootId(n[0].id);
        },
        (error) => {
            console.log(error)
        }
      );    

    const selected = useMemo(() => (
      nodes.find(item => item.id === selectId)
    ), [nodes, selectId]);

    return (
      <AppLayout>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1 1 auto', margin: '0 20px' }} className={css.root}>
            <header className={css.header}>
              <Typography variant="h1" sx={{ fontFamily: 'Acme', fontSize: 35 }}>
                Семейное дерево
              </Typography>
            </header>
            <div className="Nodes">
              {nodes.length > 0 && (
                <ReactFamilyTree
                  nodes={nodes}
                  rootId={rootId}
                  width={NODE_WIDTH}
                  height={NODE_HEIGHT}
                  className={css.tree}
                  renderNode={(node: Readonly<ExtNode>) => (
                    <FamilyNode
                      key={node.id}
                      node={(node as EextNode)}
                      isRoot={node.id === rootId}
                      isHover={node.id === hoverId}
                      onClick={setSelectId}
                      onSubClick={setRootId}
                      style={getNodeStyle(node)}
                    />
                  )}
                  />
                )}
              </div>
              {selected && (
              <NodeDetails
                node={selected}
                className={css.details}
                onSelect={setSelectId}
                onHover={setHoverId}
                onClear={() => setHoverId(undefined)}
              />
            )}
          </div>
        </div>
      </AppLayout>
    );
  },
);


