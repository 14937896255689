import { styled } from '@mui/system';
import AppLayout from '../layout/AppLayout';

import Body from './Frame/Body/Body';
import BodyLogin from './Frame/BodyLogin/BodyLogin';
import { getIsLoggedIn } from '../../services/auth.service';
import React, { useState, useEffect } from 'react';

import { getCurrentUser } from '../../services/auth.service';

interface User {
  displayName: unknown;
  login: string;
  username: string;
  password: string;
  email: string;
}

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(getIsLoggedIn()); // получаем значение isLoggedIn
  console.log("isLoggedIn: ", isLoggedIn)

  const currentUser = getCurrentUser();
  const [user, setUser] = useState<User>(currentUser); // Установка начального состояния пользователем
  console.log(user);


  const HomePageBodyFrame = styled('div')({
    marginBottom: '1%',
    
  });

    // Используйте useEffect для слежения и обновления значения isLoggedIn
    useEffect(() => {
      setIsLoggedIn(getIsLoggedIn());
    }, []); // Пустой массив зависимостей указывает на то, что useEffect будет вызван только после монтирования компонента

  return (
    <AppLayout>
      <HomePageBodyFrame>
      {isLoggedIn ? <BodyLogin /> : <Body />} 
      {/* Условное отображение компонентов: если isLoggedIn истинно, то отображается компонент BodyLogin, в противном случае отображается компонент Body */}
      </HomePageBodyFrame>
    </AppLayout>
  );
};

export default Home;
