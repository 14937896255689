import * as signalR from "@microsoft/signalr";
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ReactDOM from "react-dom";


//исходный стейт 
const initialState = {};
const API_URL = process.env.REACT_APP_BACK_SIGNALR!;

const createDialog = () => {
    const dialogRef = React.createElement(Dialog, {
        open: true,
        onClose: () => {
            const dialogContainer = document.getElementById('dialog-container');
            if (dialogContainer) {
                ReactDOM.unmountComponentAtNode(dialogContainer); // Закрываем диалоговое окно и удаляем его из DOM
            }
        },
    }, [
        React.createElement(DialogTitle, { key: 'title' }, 'Все хорошо'),
        React.createElement(DialogContent, { key: 'content' }, 'Дерево сгенерировано'),
        React.createElement(DialogActions, { key: 'actions' }, [
            React.createElement(Button, { key: 'ok-button', onClick: () => {
                const dialogContainer = document.getElementById('dialog-container');
                if (dialogContainer) {
                    ReactDOM.unmountComponentAtNode(dialogContainer); // При нажатии на кнопку "OK" закрываем диалоговое окно и удаляем его из DOM
                }
            } }, 'OK'),
        ]),
    ]);
    ReactDOM.render(dialogRef, document.getElementById('dialog-container')); // Рендерим диалоговое окно в специальный контейнер
};

type Action = { type: string, payload?: any };


// редюсер - функция, на основе входного объекта 
// меняющая стейт и возвращающая новый
const graphReducer = (state = initialState, action: Action) => {

    console.log("API to send signalR = " + API_URL);
    const hubConnection = new signalR.HubConnectionBuilder().withUrl(API_URL).build();

    hubConnection.start();
    
    hubConnection.on("sendToReact", message => {
        createDialog();
    })

    console.log('graph', state);

    switch (action.type) {        
        default:
            return state;
    }
};

document.addEventListener('DOMContentLoaded', function() {
    const dialogContainer = document.createElement('div');
    dialogContainer.id = 'dialog-container';
    document.body.appendChild(dialogContainer);
});

export default graphReducer;