import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Link from "@mui/material/Link";

export const Notify = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = () => {
        const requestData = {
            to: email,
            bcc: 'yayaya556@yandex.ru',
            cc: 'yayaya556@yandex.ru',
            from: 'yayaya556@yandex.ru',
            displayName: 'eig',
            replyTo: '',
            replyToName: '',
            subject: 'Регистрация на генетическом портале',
            body: 'Ссылка на регистрацию: http://localhost:3000/Register',
        };

        //   "to": "edemidov88@mail.ru",
        //   "bcc": "yayaya556@yandex.ru",
        //   "cc": "yayaya556@yandex.ru",
        //   "from": "yayaya556@yandex.ru",
        //   "displayName": "eig",
        //   "replyTo": "",
        //   "replyToName": "string",
        //   "subject": "spam",
        //   "body": "spam spam"

        axios.post('https://localhost:7247/api/mail/Rabbit/SendMailAsync', requestData)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

        handleClose();
    };

    return (
        <>
            <Link onClick={handleOpen}>
                {"Забыли пароль"}
            </Link>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Укажите Email для отправки</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Recipient Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSend} color="primary">Send</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};