import React from 'react';
import { Grid, styled } from '@mui/material';

const CenteredGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const MainwinBodyImage = styled('img')({
    width: '50vw',
    height: '65vh',
});

const StyledText = styled('div')({
    fontFamily: 'Acme, sans-serif',
    fontSize: '15px',
    lineHeight: 1.6,
    margin: '20px',
});


const BodyLogin: React.FC = () => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <CenteredGrid>
                    <MainwinBodyImage src="https://img.freepik.com/premium-vector/people-tree-sign-color-green-white-background-vector-illustration_3482-5142.jpg" alt="family" />
                </CenteredGrid>
            </Grid>
            <Grid item xs={6}>
                <CenteredGrid>
                    <StyledText>
                        Генеалогический портал - это как твоя семейная цифровая хроника, где можно создать удивительное семейное древо на основе знаний о своих предках, а также загрузить фотографии и создавать восхитительные семейные альбомы.
                        <br />
                        <br />
                        Что круто, так это то, что создание семейного дерева поможет тебе увидеть свои связи с предками и потомками, а также отследить историю своей семьи. Тебе будут доступны данные о предках, их биографии, места жительства и многое другое. Представь, сколько интересных историй можно узнать о своих бабушках и дедушках!
                        <br />
                        <br />
                        А создание семейных альбомов превратит воспоминания в невероятные картины, запечатлённые на страницах портала. Ты сможешь делиться этими альбомами со своими близкими и друзьями, сохраняя и передавая историю своей семьи.
                        <br />
                        <br />
                        И кстати, это еще и не только увлекательный способ провести время, но и замечательный способ сохранить историю своей семьи для будущих поколений. Так что, создавать семейное древо и альбомы - это как будто ты создаёшь свою собственную летопись!
                    </StyledText>
                </CenteredGrid>
            </Grid>
        </Grid>
    );

}

export default BodyLogin;
