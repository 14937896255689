import { useSelector } from "react-redux";
import PersonTable from "../person/person.component.list";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, FormControl, InputLabel, MenuItem, Select, Stack, TextField, ThemeProvider, Tooltip, Zoom, createTheme } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import store from "../../../store";
import { Actions } from "../../../reducers/family";
import { PersonModel } from "../../../models/people/PersonModel";
import { addPerson, getFamilyForTreeService, getPersons, removeFamilyService, removePersonService } from "../../../services/people.service";
import { FamilyWithPersonsModel } from "../../../models/people/FamilyWithPersonsModel";
import AppLayout from "../../layout/AppLayout";
import { blue } from "@mui/material/colors";
import { getCurrentUser, getIsLoggedIn } from '../../../services/auth.service';
import { User } from "../../../types/user.type";

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: {
            main: '#212121',
            light: '#F5EBFF',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fafafa',
        },
    },
});

interface PersonForAdd {
    id:string;
    firstName:string;
    middleName:string;
    lastName:string;
    dateOfBirth:string;
    dateOfDeath:string|null;
    gender:number;
    description:string;
}

const FamilyPassport = () =>  {    
    const navigate = useNavigate();
    const [family, setFamily] = useState<FamilyWithPersonsModel>();
    const [personToAdd, setPersonToAdd] = useState<PersonForAdd>();
    const [open, setOpen] = useState(false);
    const currentUser = getCurrentUser();
    const [user, setUser] = useState<User>(currentUser); // Установка начального состояния пользователем

    const [isLoggedIn, setIsLoggedIn] = useState(getIsLoggedIn()); // получаем значение isLoggedIn

    let { familyId } = useParams();

    const familyGuid = familyId !== undefined ? familyId : ''; // get from url familyId

    useEffect(() => {
        console.log('from use effect ' + familyGuid);
        if(!familyGuid || familyGuid.trim() == ''){
            console.error('Не могу загрузить людей, не выбрана семья');
            return;
        }
        getPersonsFromApi(familyGuid);
    }, []);

    const getPersonsFromApi = (familyGuid:string) =>{
        getPersons(familyGuid).then(
            (respData) => {
                setFamily(respData);
            },
            (error) => {
                console.log(error.cause);
            }
        )
    };

    const returnToFamilyList = () =>{
        if(user == null){
            return;
        }
        navigate(`/MyFamily/${user.username}`);
    }

    const handleClickAddDialog = () => {
        const p = {} as PersonForAdd;
        setPersonToAdd(p);
        setOpen(true);
    };
    const handleAddPerson = () => {
        if(!personToAdd){
            console.error('Не могу добавить человека');
            return;
        }
        addPerson(
            familyGuid,
            personToAdd.firstName,
            personToAdd.middleName,
            personToAdd.lastName,
            personToAdd.dateOfBirth,
            personToAdd.dateOfDeath ?? null,
            personToAdd.gender,
            personToAdd.description)
        .then(
            () => {
                const f = {} as FamilyWithPersonsModel;
                setFamily(f);
                getPersonsFromApi(familyGuid);
                const p = {} as PersonForAdd;
                setPersonToAdd(p);
                setOpen(false);
            },
            (error) => {
                console.log(error.cause);
            }
        );
    }
    const handleCreateTree = () => {
        getFamilyForTreeService(familyGuid)
        .then(
            (answer) => {
                console.log('family tree creating answer = ' + answer);
                navigate("/Graph");
            },
            (error) => {
                console.log(error.cause);
            }
        )
    }
    const handleClose = () => {
        const p = {} as PersonForAdd;
        setPersonToAdd(p);
        setOpen(false);
    };
    const handlePersonChange = (event:any) => {
        const localPerson = personToAdd;
        if(!event.target.id || !localPerson){
            return;
        }
        switch(event.target.id){
            case 'firstName':
                localPerson.firstName = event.target.value;
                break;
            case 'lastName':
                localPerson.lastName = event.target.value;
                break;
            case 'middleName':
                localPerson.middleName = event.target.value;
                break;
            case 'description':
                localPerson.description = event.target.value;
                break;
        }
        setPersonToAdd(localPerson);
        console.log(personToAdd);
    };
    const handleDateOfBirthChange = (event:any) => {
        const localPerson = personToAdd;
        if(!event || !localPerson){
            return;
        }
        const localDate = new Date(event);
        if(localDate instanceof Date && !isNaN(localDate.valueOf())){
            localPerson.dateOfBirth = localDate.toISOString();
            setPersonToAdd(localPerson);
            console.log(personToAdd);
        }
    }
    const handleDateOfDeathChange = (event:any) => {
        const localPerson = personToAdd;
        if(!event || !localPerson){
            return;
        }
        const localDate = new Date(event);
        if(localDate instanceof Date && !isNaN(localDate.valueOf())){
            localPerson.dateOfDeath = localDate.toISOString();
            setPersonToAdd(localPerson);
            console.log(personToAdd);
        }
    }
    const handleGenderChange = (event:any) => {
        const localPerson = personToAdd;
        if(!localPerson){
            return;
        }
        localPerson.gender = event.target.value;
        setPersonToAdd(localPerson);
        console.log(personToAdd);
    }
    const removeFamily = () =>{
        if(!familyGuid || familyGuid.trim() == ''){
            console.error('Не могу удалить семью, не выбрана семья');
            return;
        }
        console.log("family to remove " + familyGuid);
        removeFamilyService(familyGuid).then(
            () => {
                navigate('/MyFamily');
            },
            (error:any) => {
                console.log(error);
            }
        );
    }
    const removePerson = (personID:string) => {
        if(personID == null || personID.trim() == ""){
            console.error("Не могу удалить члена семьи, он не выбран");
        }
        removePersonService(personID).then(
            () => {
                const f = {} as FamilyWithPersonsModel;
                setFamily(f);
                getPersonsFromApi(familyGuid);
            },
            (error:any) => {
                console.log(error);
            }
        );
    }
    const personPassport = (personID:string) => {
        if(personID == null || personID.trim() == ""){
            console.error("Не могу перейти к члену семьи, он не выбран");
            return;
        }
        console.log(personID);
        if (family){
            const localPersonList: PersonModel[] = family.personList.map((person) => ({
                id: person.id,
                name: person.lastName + " " + person.firstName + " " + person.middleName
            }));
            if(localPersonList.length > 0){
                const curPersonIndex = localPersonList.findIndex((x:any) => x.id === personID);    
                if(curPersonIndex >= 0){
                    localPersonList.splice(curPersonIndex, 1);
                    console.log('localPersonList.len = ' + localPersonList.length);
                }
            }
            store.dispatch(Actions.setPersonsForFamily(localPersonList));
        }
        else {
            const localPersonList = {} as PersonModel[];
            store.dispatch(Actions.setPersonsForFamily(localPersonList));
        }
        
        store.dispatch(Actions.setPerson(personID));
        navigate(`/PersonPassport?familyId=${familyId}&personId=${personID}`);
    }

    if(family?.personList){
        return (

            <AppLayout>
                <ThemeProvider theme={theme}>
                    <Fragment>
                        <Box m={1} display="flex" justifyContent="flex-start" alignItems="flex-start">
                            <Stack direction="column" spacing={1}>
                                {isLoggedIn!=null ?<Button variant="outlined" sx={{ mt: 3, mb: 2 }} color="secondary" onClick={() => returnToFamilyList()} >Назад</Button>: null}
                                {isLoggedIn!=null ?<Button variant="outlined" sx={{ mt: 3, mb: 2 }} color="secondary" onClick={() => removeFamily()} >Удалить семью</Button>: null}
                                {isLoggedIn!=null ?<Button variant="contained" sx={{ mt: 3, mb: 2 }} color="secondary" onClick={() => handleClickAddDialog()} >Добавить члена семьи</Button>: null}
                                {isLoggedIn!=null ?<Button variant="contained" sx={{ mt: 3, mb: 2 }} color="secondary" onClick={() => handleCreateTree()} >Создать семейное дерево</Button>: null}
                            </Stack>
                        </Box>
                        <PersonTable familyName={family ? "Семья " + family.name : "Ошибка загрузки"} personList={family?.personList || []} removePerson={removePerson} personPassport={personPassport} />
                        <div>
                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                                <DialogTitle>Новый член семьи</DialogTitle>
                                <Divider />
                                <DialogContent >
                                    <Stack spacing={1}>
                                        <TextField
                                            name="lastName"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Введите фамилию"
                                            value={personToAdd?.lastName}
                                            onChange={handlePersonChange}
                                        />
                                        <TextField
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="Введите имя"
                                            value={personToAdd?.firstName}
                                            onChange={handlePersonChange}
                                            autoFocus
                                        />
                                        <TextField
                                            name="middleName"
                                            required
                                            fullWidth
                                            id="middleName"
                                            label="Введите отчество"
                                            value={personToAdd?.middleName}
                                            onChange={handlePersonChange}
                                        />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Введите дату рождения"
                                                onChange={(newValue) => handleDateOfBirthChange(newValue)}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Введите дату смерти или оставьте поле пустым"
                                                onChange={handleDateOfDeathChange}
                                            />
                                        </LocalizationProvider>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Укажите пол</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="gender"
                                                label="Укажите пол"
                                                required
                                                onChange={(newValue) => handleGenderChange(newValue)}
                                            >
                                                <MenuItem value={0}>Мужской</MenuItem>
                                                <MenuItem value={1}>Женский</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            name="description"
                                            fullWidth
                                            id="description"
                                            label="Введите описание"
                                            multiline
                                            rows={3}
                                            value={personToAdd?.description}
                                            onChange={handlePersonChange}
                                        />
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button sx={{ mt: 3, mb: 2 }} color="secondary" onClick={handleAddPerson}>Сохранить</Button>
                                    <Button sx={{ mt: 3, mb: 2 }} color="secondary" onClick={handleClose}>Отмена</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Fragment>
                </ThemeProvider>
            </AppLayout>
        );
    }
    else {
        return (
            <AppLayout>
                <div style={{ fontSize: 30, textAlign: 'center' }}>Загрузка...</div>
            </AppLayout>

        )
    }
}

export default FamilyPassport;