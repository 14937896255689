import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useNavigate} from 'react-router-dom';

interface RenderComponentDialogProps {
    component: React.ReactNode;
}

const RenderComponentDialog: React.FC<RenderComponentDialogProps> = ({component}) => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    {component}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate('/')}>ДОМОЙ</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default RenderComponentDialog;