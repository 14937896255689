import React from 'react';
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import { styled } from '@mui/system';

import { getIsLoggedIn } from '../../../../services/auth.service';
import HeaderMenuLogin from '../HeaderMenu/HeaderMenuLogin';

const BigHeaderText = styled('div')({
  fontFamily: 'Acme',
  fontSize: 40,
  color: 'rgba(0, 0, 0, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: '1%',
});

const SmallHeaderText = styled('div')({
  fontFamily: 'Acme',
  fontSize: 30,
  color: 'rgba(0, 0, 0, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: '0.5%',
  paddingBottom: '1%',
});

const style = {
  width: '100%',
  backgroundColor: 'black'
};

const Header = () => {
  const isLoggedIn = getIsLoggedIn(); // получаем значение isLoggedIn

  return (
    <>
      <div style={style}>
        {isLoggedIn ? <HeaderMenuLogin /> : <HeaderMenu />}
      </div>
      <BigHeaderText>ГЕНЕАЛОГИЧЕСКИЙ ПОРТАЛ</BigHeaderText>
      <SmallHeaderText>Собери свое семейное дерево</SmallHeaderText>
    </>
  );
}

export default Header;
