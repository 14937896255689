import { NavLink } from 'react-router-dom';
import { Grid, Link } from '@mui/material';
import React from 'react';

const linkStyles = {
    color: 'white',
    fontFamily: 'Acme',
    fontWeight: 100,
    fontSize: 15,
    textDecoration: 'none',
    marginX: 2,
};

const HeaderMenu = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                bgcolor: '#000000',
                height: 57,
                width: '100%',
                display: 'flex',
            }}
        >
            <Link component={NavLink} to='/' variant="button" sx={linkStyles}>О портале</Link>
            <Link component={NavLink} to='/' variant="button" sx={linkStyles}>Блоги</Link>
            <Link component={NavLink} to='/' variant="button" sx={linkStyles}>Помощь</Link>
            <Link component={NavLink} to='/Login' variant="button" sx={linkStyles}>Войти</Link>
            <Link component={NavLink} to='/Register' variant="button" sx={linkStyles}>Зарегистрироваться</Link>
        </Grid>
    );
}

export default HeaderMenu;
