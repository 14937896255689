import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lime, blue } from '@mui/material/colors';
import AppLayout from '../layout/AppLayout';

import { NavigateFunction, useNavigate } from 'react-router-dom';

import { register } from "../../services/auth.service";

import axios from 'axios';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#212121',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fafafa',
    },
  },
});

export default function Register() {

  let navigate: NavigateFunction = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
      passwordConfirm: data.get('passwordConfirm'),
      displayName: data.get('displayName'),
    });

    let email: string = data.get('email') as string;
    let password: string = data.get('password') as string;
    let passwordConfirm: string = data.get('passwordConfirm') as string;
    let displayName: string = data.get('displayName') as string;

    register(email, password, passwordConfirm, displayName).then(
      () => {

        const requestData = {
          to: 'yayaya556@yandex.ru', // admin email // can change for everyone
          bcc: 'yayaya556@yandex.ru',
          cc: 'yayaya556@yandex.ru',
          from: 'yayaya556@yandex.ru', // main server mail
          displayName: 'eig',
          replyTo: '',
          replyToName: '',
          subject: 'Запрос на регистрацию на генетическом портале',
          body: 'email = ' + email + ' password = ' + password + ' displayName = ' + displayName,
      };

      axios.post('https://localhost:7247/api/mail/Mail/SendMailAsync', requestData)
          .then(response => {
              console.log(response.data);
          })
          .catch(error => {
              console.error(error);
          });

        navigate("/Login");
        //window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        //setLoading(false);
        //setMessage(resMessage);
      }
    );
  };

  return (
    <AppLayout>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" sx={{height: '58vh'}}>
          <CssBaseline />
          <Box
            sx={{
              marginBottom: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Регистрация
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="given-name"
                    name="displayName"
                    required
                    fullWidth
                    id="displayName"
                    label="Введите ваш логин"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Введите адрес электронной почты"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Введите пароль"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Повторите пароль"
                    type="password"
                    id="passwordConfirm"
                    autoComplete="confirm-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="Я соглашаюсь получать уведомления по почте"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="secondary"
              >
                Зарегестрироваться
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/Login" variant="body2">
                    {"У вас уже есть аккаунт? Войти"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </AppLayout>
  );
}