import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import msgReducer from './reducers/message';
import familyReducer from './reducers/family';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import photoSlice from './reducers/photoSlice';
import graphReducer from './reducers/graph';



const store = configureStore({
    reducer: {
        // Регистрируем два редюсера
        auth: authReducer,
        msg: msgReducer,
        family: familyReducer,
        albums: photoSlice,
        graph: graphReducer
    },

});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector