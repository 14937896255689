import { Fragment, useEffect, useState } from "react";
import FamilyList from "./family.component.list";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, TextField, Typography, ThemeProvider, Tooltip, Zoom, createTheme, Stack } from "@mui/material";
import store from "../../../store";
import { Actions } from "../../../reducers/family";
import { useNavigate, useParams } from "react-router-dom";
import { addFamily, getFamilies } from "../../../services/people.service";
import { FamilyModel } from "../../../models/people/FamilyModel";
import React from "react";
import { blue } from "@mui/material/colors";
import { margin, styled } from '@mui/system';
import Container from '@mui/material/Container';

import AppLayout from "../../layout/AppLayout";
import Sidebar from "../../sidebar/Sidebar";
import { User } from "../../../types/user.type";
import { getCurrentUser } from "../../../services/auth.service";
// добавляем тип для импортированного компонента
type AppLayoutProps = {
    children: React.ReactNode; // или более конкретный тип, если у вас есть заранее определенный тип children
};

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: {
            main: '#212121',
            light: '#F5EBFF',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fafafa',
        },
    },
});

const SmallHeaderText = styled('div')({
    fontFamily: 'Acme',
    fontSize: 30,
    color: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '0.5%',
    paddingBottom: '1%',
  });

export default function Family() {

    const [familyList, setFamilyList] = useState<FamilyModel[]>([]);
    const [open, setOpen] = useState(false);
    const [familyValue, setfamilyValue] = useState("");
    const [familyDescriptionValue, setfamilyDescriptionValue] = useState("");

    const navigate = useNavigate();

    const user:User = getCurrentUser();

    const getFamiliesFromApi = () => {
        console.log('This is user=');
        console.log(user);
        if(user == null){
            navigate('/');
            return;
        }
        getFamilies(user.id).then(
            (rcvData) => {
                setFamilyList(rcvData);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getFamiliesFromApi();
    }, []);

    const handleClickOpen = () => {
        setfamilyValue("");
        setfamilyDescriptionValue("");
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleFamilyValueChange = (event: any) => {
        setfamilyValue(event.target.value);
    };
    const handleFamilyDescriptionValueChange = (event: any) => {
        setfamilyDescriptionValue(event.target.value);
    };
    const handleSubmit = () => {
        const userID = user.id;
        const name = familyValue as string;
        const description = familyDescriptionValue as string;
        if (userID.trim() == '' || name.trim() == '' || description.trim() == '') {
            console.error("Ошибка добавления семьи: пустые параметры");
            return;
        }
        addFamily(name, description, userID).then(
            () => {
                getFamiliesFromApi();
            },
            (error) => {
                console.log(error.cause);
            }
        );
        handleClose();
    };

    const openFamily = (index: string) => {
        console.log(index);
        store.dispatch(Actions.setFamily(index));
        navigate('/FamilyPassport/' + index);
    };

    return (
        <AppLayout>
            <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xl">
                        <Box sx={{ width: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography component="h5" variant="h6">
                                Список семей
                            </Typography>
                        </Box>
                        <Stack direction="row" spacing={2}>
                            <Box m={1} sx={{ width: "20%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => handleClickOpen()} >Добавить семью</Button>
                            </Box>
                            <Box sx={{ width: "80%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <FamilyList items={familyList} openFamilyParent={openFamily} />
                            </Box>
                        </Stack>
                    </Container>
                    <div>
                        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                            <DialogTitle>Новая семья</DialogTitle>
                            <Divider />
                            <DialogContent >
                                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1 } }} noValidate autoComplete="off">
                                    <TextField
                                        name="familyName"
                                        required
                                        fullWidth
                                        id="familyName"
                                        label="Введите наименование семьи"
                                        value={familyValue}
                                        onChange={handleFamilyValueChange}
                                        autoFocus
                                    />
                                    <TextField
                                        name="familyDescription"
                                        required
                                        fullWidth
                                        id="familyDescription"
                                        label="Введите описание семьи"
                                        value={familyDescriptionValue}
                                        onChange={handleFamilyDescriptionValueChange}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSubmit}>Сохранить</Button>
                                <Button onClick={handleClose}>Отмена</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
            </ThemeProvider>
        </AppLayout>
    );
};